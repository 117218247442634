import { Box, Checkbox } from "@mui/material";
import React from "react";

const CustomCheckbox = ({ isChecked, onChange, label }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", ml: 3 }}>
      <p>{label}</p>
      <Checkbox
        checked={isChecked}
        onChange={onChange}
        inputProps={{ "aria-label": "controlled" }}
      />
    </Box>
  );
};

export default CustomCheckbox;
