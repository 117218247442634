import React, { useState } from "react";
import { useUpdateNotificationMutation } from "state/api";
import styled from "styled-components";

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  gap: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #0d0f23;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;

  &:hover {
    background-color: #4a90e2;
  }
`;

const Check = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ checked }) => (checked ? "#4caf50" : "#e0e0e0")};
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
`;

const NotificationCard = ({
  _id,
  seen,
  developerName,
  solved,
  nextNotification,
  createdAt,
  ...notifInfo
}) => {

  const [updateNotif] = useUpdateNotificationMutation();

  const [date, setDate] = useState(
    new Date(nextNotification).toISOString().slice(0, 10)
  );
  const [seenCheck, setSeenCheck] = useState(seen);
  const [solvedCheck, setSolvedCheck] = useState(solved);

  const handleDateChange = async (event) => {
    event.preventDefault();

    const value = event.target.value;
    const parsedDate = new Date(value).toISOString().slice(0, 10);
    setDate(parsedDate);
    try {
      const response = await updateNotif({_id, nextNotification: parsedDate}).unwrap();
      console.log("Notification updated successfully", response);
    } catch (error) {
      console.error("Failed to update user", error);
    }
  };

  const handleSeenChange = async (event) => {
    event.preventDefault();
    setSeenCheck(!seenCheck);
    try {
      const response = await updateNotif({ _id, seen: !seen }).unwrap();
      console.log("VISTO:", response);
    } catch (error) {
      console.error("Failed to update user", error);
    }
  }

  const handleSolvedChange = async (event) => {
    event.preventDefault();
    setSolvedCheck(!solvedCheck);
    try {
      const response = await updateNotif({ _id, solved: !solved }).unwrap();
      console.log("RESUELTO:", response);
    } catch (error) {
      console.error("Failed to update user", error);
    }
  }

  return (
    <CardWrapper>
      <span>{developerName}</span>
      <span>{notifInfo.project.name}</span>
      <span>{notifInfo.project.plan}</span>
      <Check onClick={handleSeenChange} checked={seenCheck}>
        {seen ? "✓" : "-"}
      </Check>
      <input type="date" value={date} onChange={handleDateChange} />
      <Check onClick={handleSolvedChange} checked={solvedCheck}>
        {solved ? "✓" : "-"}
      </Check>
      <span>{new Date(createdAt).toISOString().slice(0, 10)}</span>
    </CardWrapper>
  );
};

export default NotificationCard;
