export const fetchDataNOL = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}developer/updateNumberOfLeads`
    );
    return await response.json();
  } catch (error) {
    console.log("Error al actualizar los datos:", error);
  }
};

export const refreshAllDB = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}developer/generateNewDevelopers`
    );
    return await response.json();
  } catch (error) {
    console.log("Error al actualizar los datos:", error);
  }
}

export const sendEmailAlert = async (to, text) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}email/sendAlert`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ to, text }),
      }
    );

    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

export const updateNextNotification = async (id, newDate) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}notifs/updateNotif/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ nextNotification: newDate }),
      }
    );
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};
