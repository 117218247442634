import React, { useState, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  useGetDeveloperByIdQuery,
  useUpdateDeveloperMPMutation,
  useUpdateDeveloperMutation,
} from "state/api";
import styles from "./developer.module.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import { Box, Button, useTheme } from "@mui/material";
import DateInput from "components/DateInput";

// AL HACER LA PETICION A MONGO POR LE ID DEL DEVELOPER,
// PONER EL OBJETO QUE TRAE COMO DEFAULT VALUE PARA QUE SE PRECARGUEN LOS CAMPOS
const EditUserForm = ({ currentUser }) => {
  const [hasItsOwnSheet, setHasItsOwnSheet] = useState(false);
  const [keyValue, setKeyValue] = useState("");

  const theme = useTheme();
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const { data } = useGetDeveloperByIdQuery(currentUser[0]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm();

  const [updateDeveloper] = useUpdateDeveloperMutation();
  const [updateDeveloperMP] = useUpdateDeveloperMPMutation();

  const onSubmit = async (data) => {
    console.log({ _id: currentUser[0], ...data });

    try {
      const { name, isActive } = data;
      if (hasItsOwnSheet) {
        await updateDeveloperMP({
          key: keyValue,
          name,
          isActive,
        }).unwrap();
      }
      
      // Parse date from DD-MM-YYYY to YYYY-MM-DD
      data.scheduledTerminationDeveloper = data.scheduledTerminationDeveloper.split('-').reverse().join('-')

      await updateDeveloper({ _id: currentUser[0], ...data }).unwrap();
      Swal.fire({
        title: "Buen Trabajo!",
        text: "Comercializadora actualizada!",
        icon: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/crear-comercializadora");
          window.location.reload();
        }
      });
    } catch (error) {
      console.log(error);
      MySwal.fire({
        icon: "error",
        title: "¡ERROR!",
        text: "Hubo un fallo al crear la comercializadora",
        footer:
          "Comprueba que la comercializadora que intentas crear no exista...",
      });
    }
  };

  const handleOwnSheetChange = (event) => {
    setHasItsOwnSheet(event.target.value === "true");
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "projects",
  });

  useEffect(() => {
    if (data) {
      // Set form values for developer info
      setValue("name", data.name);
      setValue("isActive", data.isActive);

      // Set form values for each project in the array
      data.projects.forEach((project, index) => {
        setValue(`projects.${index}.name`, project.name);
        setValue(`projects.${index}.target`, project.target);
        setValue(`projects.${index}.plan`, project.plan);
        setValue(`projects.${index}.numberOfLeads`, 0);
        // Set other project values here
      });
    }
  }, [data, setValue]);

  return (
    <Box className={styles.formContainer} sx={{ maxHeight: "80vh", overflowY: "auto" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="text"
          className={styles.inputField}
          placeholder="Nombre"
          {...register("name", { required: true })}
        />
        {errors.name && (
          <p className={styles.errorText}>
            Ingresa el nombre de la desarrolladora
          </p>
        )}
        <div className={styles.checkboxContainer}>
          <label className={styles.checkboxLabel}>Activo</label>
          <input
            type="checkbox"
            className={styles.checkboxInput}
            {...register("isActive", {})}
          />
        </div>

        <Box className={styles.radioGroup}>
          <label className={styles.radioLabel}>
            Tiene sheet propia?
            <input
              type="radio"
              name="hasOwnSheet"
              value={true}
              className={styles.radioInput}
              onChange={handleOwnSheetChange}
            />
            <span className={styles.radioIndicator}></span>
            <span>Sí</span>
          </label>
          <label className={styles.radioLabel}>
            <input
              type="radio"
              name="hasOwnSheet"
              value={false}
              className={styles.radioInput}
              onChange={handleOwnSheetChange}
            />
            <span className={styles.radioIndicator}></span>
            <span>No</span>
          </label>
        </Box>
        {hasItsOwnSheet && (
          <input
            type="text"
            placeholder="Nombre de su sheet"
            value={keyValue}
            onChange={(e) => setKeyValue(e.target.value)}
            className={styles.projectField}
          />
        )}

        {fields.map((field, index) => (
          <Box key={field.id}>
            <input
              type="text"
              className={styles.projectField}
              placeholder={`Nombre del proyecto ${index + 1}`}
              {...register(`projects.${index}.name`, { required: true })}
            />
            {errors?.projects?.[index]?.name && (
              <p className={styles.errorText}>Ingresa un nombre de proyecto</p>
            )}

            <input
              type="number"
              className={styles.projectField}
              placeholder="Objetivo"
              {...register(`projects.${index}.target`, {
                required: true,
                min: 11,
              })}
            />
            {errors?.projects?.[index]?.target && (
              <p className={styles.errorText}>Ingresa un objetivo válido</p>
            )}

            <select
              className={styles.selectField}
              {...register(`projects.${index}.plan`, { required: true })}
            >
              <option value="Starter">Starter</option>
              <option value="Black"> Black</option>
              <option value="Acciones"> Acciones</option>
              <option value="Tiempo"> Tiempo</option>
              <option value="Impulso"> Impulso</option>
              <option value="Impulso M"> Impulso M</option>
              <option value="Impulso B"> Impulso B</option>
              <option value="Impulso E"> Impulso E</option>
              <option value="Impulso LATAM"> Impulso LATAM</option>
              <option value="Impulso LATAM E"> Impulso LATAM E</option>
            </select>
            {errors?.projects?.[index]?.plan && (
              <p className={styles.errorText}>Selecciona un plan</p>
            )}

            <input
              type="hidden"
              {...register(`projects.${index}.numberOfLeads`, { value: 0 })}
            />

            <Button
              sx={{
                backgroundColor: theme.palette.alert.error,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginBottom: "10px",
              }}
              type="button"
              onClick={() => remove(index)}
            >
              REMOVER
            </Button>
          </Box>
        ))}

        <DateInput register={register} errors={errors} styles={styles}/>


        <Button
          sx={{
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.background.alt,
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            margin: "5px",
          }}
          type="button"
          onClick={() => append({ name: "" })}
        >
          AÑADIR PROYECTO
        </Button>

        <Button
          sx={{
            backgroundColor: theme.palette.alert.success,
            color: theme.palette.background.alt,
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            margin: "5px",
          }}
          type="submit"
        >
          ACTUALIZAR
        </Button>
      </form>
    </Box>
  );
};

export default EditUserForm;
