import React, { Fragment, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Box, Button, Modal, useTheme } from "@mui/material";
import EditUserForm from "./EditDeveloper";
import { useGetDeveloperFromMongoQuery } from "state/api";
import { useNavigate } from "react-router-dom";

const DevelopersTable = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { data } = useGetDeveloperFromMongoQuery();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [array, setArray] = useState([]);
  const [arrayEdit, setArrayEdit] = useState({});

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const editButton = (user) => {
    console.log(user);
    setEdit(true);
    setArrayEdit(user);
    handleOpen();
  };

  const updateUser = (id, updatedUser) => {
    setEdit(false);
    const updatedArray = array.map((user) =>
      user[0] === id
        ? [
            updatedUser.id,
            updatedUser.name,
            updatedUser.username,
            updatedUser.email,
            "",
          ]
        : user
    );
    setArray(updatedArray);
    handleClose();
  };

  const columns = [
    {
      name: "_id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Nombre",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "isActive",
      label: "Activo",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value ? "SI" : "NO";
        },
      },
    },
    {
      name: "Edicion",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              onClick={() => {
                editButton(tableMeta.rowData);
              }}
              className="button muted-button"
            >
              Editar
            </button>
          );
        },
      },
    },
  ];
  // const data = [
  //   {_id: 1, name: "Codas", isActive: true ? 'SI' : 'NO' },
  //   {_id: 2, name: "Miraglia", isActive: false},
  //   {_id: 456, name: "Budnes", isActive: true},
  // ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none"
  };

  return (
    <>
      <MUIDataTable
        title={"Comercializadoras"}
        data={data}
        columns={columns}
        options={options}
      />
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div className="modal">
          {edit ? (
            <Fragment>
              <h2 id="simple-modal-title">Editar Usuário</h2>
              <div id="simple-modal-description">
                <EditUserForm
                  editing={edit}
                  currentUser={arrayEdit}
                  updateUser={updateUser}
                  closeModal={handleClose}
                />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <h2 id="simple-modal-title">Adicionar Usuário</h2>
              <div id="simple-modal-description">
                <p>ACA SE DEBERIA AÑADIR UN USER</p>
              </div>
            </Fragment>
          )}
        </div>
      </Modal>
      <Box>
        <Button
          sx={{
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.background.alt,
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
          onClick={(e) => navigate("/create-developer")}
        >
          Añadir Comercializadora
        </Button>
      </Box>
    </>
  );
};

export default DevelopersTable;
