import { calcTotal } from "./leadsOperations";

export const sortByTrends = (developers, statusFilter) => {
  const devsWithTrends = developers.map((developer) => {
    return {
      ...developer,
      trend: calcTrendsByProjects(developer),
    };
  });
  const sortedArray = devsWithTrends.sort((a, b) => {
    // if (isNaN(a.trend)) {
    //   return 1;
    // }
    // if (isNaN(b.trend)) {
    //   return -1;
    // }
    return a.trend - b.trend;
  });
  return filterByStatus(sortedArray, statusFilter);
};

const calcTrendsByProjects = (dev) => {
  if(!dev.projects.length) {
    return 0
  }
  let sum = 0;
  const daysElapsed = daysElapsedInMonth();
  const daysInMonth = getDaysInCurrentMonth();
  dev.projects.forEach((project) => {
    let dailyTarget = project.target / daysInMonth;
    let targetForThisDay = dailyTarget * daysElapsed;
    let trend = (project.numberOfLeads / targetForThisDay) * 100;
    sum += trend;
  });
  return sum / dev.projects.length;
};

const getDaysInCurrentMonth = () => {
  const currentDate = new Date(); // Gets the current date
  const currentMonth = currentDate.getMonth(); // Gets the current month (0 - 11)
  const currentYear = currentDate.getFullYear(); // Gets the current year
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Gets the number of days in the current month
  return daysInMonth;
};

/**
 * Funcion que devuelve cuantos dias van del mes, ejemplo si es 12 de Abril devolvera 12.
 * @returns dias
 */
const daysElapsedInMonth = () => {
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const daysElapsed =
    Math.floor((today - startOfMonth) / millisecondsPerDay) + 1;
  return daysElapsed;
};

const filterByStatus = (developers, filter) => {
  if(filter === 3) return developers;
  return developers.filter((developer) => {
  const { totalLeads, totalTar } = calcTotal(developer.projects);
    
  // VERDE
    if(filter === 0 && totalLeads >= totalTar) return true;

  // ROJO
    if(filter === 1 && (developer.trend) < 100) return true;

  // AMARILLO
    if(filter === 2 && (developer.trend) >= 100 && totalLeads < totalTar) return true;

  })
}

export const sortByName = (developers) => {
  return developers.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
}
