import { Box } from "@mui/material";
import React, { useState } from "react";

const DateInput = ({ register, errors, styles }) => {
    // Estado para almacenar el valor del input
    const [date, setDate] = useState('');

    const validateDate = (value) => {
        if (!value) return true; // Permitir campos vacíos
        const [day, month, year] = value.split('-').map(Number);
        return (
          day >= 1 && day <= 31 &&
          month >= 1 && month <= 12 &&
          year >= 2022 && year <= 2100
        );
      };

    // Función para manejar cambios en el input
    const handleInputChange = (event) => {
      const inputValue = event.target.value;
      // Utilizamos una expresión regular para eliminar todos los caracteres que no sean dígitos
      const cleanedValue = inputValue.replace(/[^\d]/g, '');
  
      // Formateamos la fecha con guiones automáticamente
      if (cleanedValue.length <= 2) {
        // Formato DD
        setDate(cleanedValue);
      } else if (cleanedValue.length <= 4) {
        // Formato DD-MM
        setDate(`${cleanedValue.slice(0, 2)}-${cleanedValue.slice(2)}`);
      } else {
        // Formato DD-MM-YYYY
        setDate(`${cleanedValue.slice(0, 2)}-${cleanedValue.slice(2, 4)}-${cleanedValue.slice(4, 8)}`);
      }
    };

    return (
      <Box>
        <label className={styles.checkboxLabel}>Fecha de baja:</label>
        <input
        {...register('scheduledTerminationDeveloper', {
            validate: validateDate,
          })}
          type="text"
          value={date}
          className={styles.inputField}
          onChange={handleInputChange}
          placeholder="DD-MM-YYYY"
          maxLength="10" // Longitud máxima permitida para la fecha
        />
        {errors.scheduledTerminationDeveloper && <p className={styles.errorText}>Fecha inválida</p>}
      </Box>
    );
  }
  
  export default DateInput;