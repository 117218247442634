import React, { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  useCreateDeveloperMPMutation,
  useCreateDeveloperMutation,
} from "state/api";
import styles from "./developer.module.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import { Box, Button, useTheme } from "@mui/material";
import DateInput from "components/DateInput";

const CreateDeveloper = () => {
  const [hasItsOwnSheet, setHasItsOwnSheet] = useState(false);
  const [keyValue, setKeyValue] = useState("");

  const theme = useTheme();
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      isActive: true,
    },
  });

  const [addNewDeveloper] = useCreateDeveloperMutation();
  const [addNewDeveloperMP] = useCreateDeveloperMPMutation();

  const onSubmit = async (data) => {
    try {
      const { name, isActive } = data;
      if (hasItsOwnSheet) {
        await addNewDeveloperMP({ key: keyValue, name, isActive }).unwrap();
      }
      // Parse date from DD-MM-YYYY to YYYY-MM-DD
      data.scheduledTerminationDeveloper = data.scheduledTerminationDeveloper.split('-').reverse().join('-')

      await addNewDeveloper(data).unwrap();
      Swal.fire({
        title: "Buen Trabajo!",
        text: "Comercializadora creada correctamente!",
        icon: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/crear-comercializadora");

          window.location.reload();
        }
      });
    } catch (error) {
      console.log(error);
      MySwal.fire({
        icon: "error",
        title: "¡ERROR!",
        text: "Hubo un fallo al crear la comercializadora",
        footer:
          "Comprueba que la comercializadora que intentas crear no exista...",
      });
    }
  };

  const handleOwnSheetChange = (event) => {
    setHasItsOwnSheet(event.target.value === "true");
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "projects",
  });

  return (
    <Box className={styles.formContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="text"
          className={styles.inputField}
          placeholder="Nombre"
          autoFocus
          {...register("name", { required: true })}
        />
        {errors.name && (
          <p className={styles.errorText}>
            Ingresa el nombre de la desarrolladora
          </p>
        )}
        <div className={styles.checkboxContainer}>
          <label className={styles.checkboxLabel}>Activo</label>
          <input
            type="checkbox"
            className={styles.checkboxInput}
            {...register("isActive", {})}
          />
        </div>
        <Box className={styles.radioGroup}>
          <label className={styles.radioLabel}>
            TIENE SHEET PROPIA?
            <input
              type="radio"
              name="hasOwnSheet"
              value={true}
              className={styles.radioInput}
              onChange={handleOwnSheetChange}
            />
            <span className={styles.radioIndicator}></span>
            <span>Sí</span>
          </label>
          <label className={styles.radioLabel}>
            <input
              type="radio"
              name="hasOwnSheet"
              value={false}
              className={styles.radioInput}
              onChange={handleOwnSheetChange}
            />
            <span className={styles.radioIndicator}></span>
            <span>No</span>
          </label>
        </Box>
        {hasItsOwnSheet && (
          <input
            type="text"
            placeholder="Nombre de su sheet"
            value={keyValue}
            onChange={(e) => setKeyValue(e.target.value)}
            className={styles.projectField}
          />
        )}
        {fields.map((field, index) => (
          <Box key={field.id}>
            <input
              type="text"
              className={styles.projectField}
              placeholder={`Nombre del proyecto ${index + 1}`}
              {...register(`projects.${index}.name`, { required: true })}
            />
            {errors?.projects?.[index]?.name && (
              <p className={styles.errorText}>Ingresa un nombre de proyecto</p>
            )}

            <input
              type="number"
              className={styles.projectField}
              placeholder="Objetivo"
              {...register(`projects.${index}.target`, {
                required: true,
                min: 11,
              })}
            />
            {errors?.projects?.[index]?.target && (
              <p className={styles.errorText}>Ingresa un objetivo válido</p>
            )}

            <select
              className={styles.selectField}
              {...register(`projects.${index}.plan`, { required: true })}
            >
              <option value="Starter">Starter</option>
              <option value="Black"> Black</option>
              <option value="Acciones"> Acciones</option>
              <option value="Tiempo"> Tiempo</option>
              <option value="Impulso"> Impulso</option>
              <option value="Impulso M"> Impulso M</option>
              <option value="Impulso B"> Impulso B</option>
              <option value="Impulso E"> Impulso E</option>
              <option value="Impulso LATAM"> Impulso LATAM</option>
              <option value="Impulso LATAM E"> Impulso LATAM E</option>
            </select>
            {errors?.projects?.[index]?.plan && (
              <p className={styles.errorText}>Selecciona un plan</p>
            )}

            <input
              type="hidden"
              {...register(`projects.${index}.numberOfLeads`, { value: 0 })}
            />

            <Button
              sx={{
                backgroundColor: theme.palette.alert.error,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginBottom: "10px",
              }}
              type="button"
              onClick={() => remove(index)}
            >
              REMOVER
            </Button>
          </Box>
        ))}

        <DateInput register={register} errors={errors} styles={styles}/>

        <Button
          sx={{
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.background.alt,
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            margin: "5px"
          }}
          type="button"
          onClick={() => append({ name: "" })}
        >
          AÑADIR PROYECTO
        </Button>

        <Button
          type="submit"
          sx={{
            backgroundColor: theme.palette.alert.success,
            color: theme.palette.background.alt,
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            margin: "5px"
          }}
        >
          CREAR
        </Button>
      </form>
    </Box>
  );
};

export default CreateDeveloper;
