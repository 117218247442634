import React from "react";
import styled from "styled-components";
import { calcPercentage, calcTotal } from "utils/leadsOperations";

// {
//     "cliente": "Cliente 1",
// "proyectos": [
//   {
//     "nombre": "Proyecto 1",
//     "cantidadLeads": 100,
//     "objetivo": "Aumentar ventas en un 20%"
//   },
//   {
//     "nombre": "Proyecto 2",
//     "cantidadLeads": 50,
//     "objetivo": "Generar 200 nuevos registros"
//   },
//   {
//     "nombre": "Proyecto 3",
//     "cantidadLeads": 75,
//     "objetivo": "Posicionar marca en el mercado"
//   }
// ]
//   }

const Container = styled.div`
  width: 400px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const Column = styled.th`
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;

  &.column-green {
    background-color: #2dc937;
    font-weight: bold;
  }

  &.column-yellow {
    background-color: #e7b416;
    font-weight: bold;
  }

  &.column-red {
    background-color: #cc3232;
    font-weight: bold;
  }
`;

const Cell = styled.td`
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
`; //td

const TotalCell = styled(Cell)`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;

function CustomerDataGrid({ devName, projects, trend }) {
  const { totalLeads, totalTar } = calcTotal(projects);
  const percentaje = calcPercentage(totalLeads, totalTar);
  let status = ((trend) >= 100) ? 'column-yellow' : 'column-red';
  if (totalLeads >= totalTar) status = 'column-green';
  return (
    <div>
      <h2>{devName}</h2>
      <Table>
        <thead>
          <tr>
            <Column className={status}>Nombre del Proyecto</Column>
            <Column className={status}>Leads Actuales</Column>
            <Column className={status}>Objetivo</Column>
            <Column className={status}>Total</Column>
          </tr>
        </thead>
        <tbody>
          {projects.map((proyecto, index) => 
            (<tr key={proyecto.name}>
              <Cell>{proyecto.name}</Cell>
              <Cell>{proyecto.numberOfLeads}</Cell>
              <Cell>{proyecto.target}</Cell>
              {!index ? <TotalCell rowSpan="4">{`${totalLeads}/${totalTar} %${Math.round(percentaje)}`}</TotalCell> : null}
            </tr>)
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default CustomerDataGrid;
