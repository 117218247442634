import React, { useEffect, useState } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  CachedOutlined,
  Email,
  PointOfSale,
  PersonAdd,
  Traffic,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  useGetDeveloperFromMongoQuery,
  useGetLastDBUpdateQuery,
  useGetNotificationsQuery,
  useGetNumOfActiveDevsQuery,
} from "state/api";
import StatBox from "components/StatBox";

import Swal from "sweetalert2/dist/sweetalert2.js";
import withReactContent from "sweetalert2-react-content";

import CustomerDataGrid from "components/CustomerDataGrid";
import { sortByName, sortByTrends } from "utils/trends";

import CustomSelect from "components/CustomSelect";
import { statusFilterSelectData } from "data/selectData";
import { fetchDataNOL, refreshAllDB } from "utils/fetch";
import Searchbar from "components/Searchbar";
import CustomCheckbox from "components/CustomCheckbox";
import { checkNotifications } from "utils/notifs";

const Dashboard = () => {
  const theme = useTheme();

  const MySwal = withReactContent(Swal);

  const [statusData, setStatusData] = useState(3);
  const [updateDB, setUpdateDB] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const activeDevelopers = useGetNumOfActiveDevsQuery();
  const { data, isLoading } = useGetDeveloperFromMongoQuery();
  const notifications = useGetNotificationsQuery();
  const lastDBUpdate = useGetLastDBUpdateQuery();
  // PONER EL UPDATE DE LA DB DE LOS NEW DEVELOPER EN EL BOTON DE REPORT (SUBIR TODO A PRODU)

  // COMPROBAR SI HAY NOTIFICACIONES Y LANZAR UNA ALERTA
  useEffect(() => {
    if (notifications && notifications.data) {
      // Compruebo que haya notis sin resolver
      let hasNotifs = notifications.data.notifs.some(
        (notification) => notification.solved === false
      );
      if (hasNotifs) {
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: "Tienes notificaciónes que no han sido resueltas!",
          footer: "<b>Ve a la ruta de notificaciones para revisarlo</b>",
        });
        checkNotifications(notifications.data.notifs);
      }
    }
  }, [notifications]);

  let customerArrSorted = [];
  if (data) {
    customerArrSorted = sortByTrends(data, statusData);
  }
  const filteredData =
    customerArrSorted.length > 0
      ? customerArrSorted.filter((item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase()) && item.isActive
        )
      : [];

  const handleGetGoals = () => {
    let cumplidos = 0;
    let noCumplidos = 0;
    console.log("filteredData", filteredData);
    filteredData.forEach((cliente) => {
      if (cliente.trend >= 100) {
        cumplidos++;
      } else if (
        cliente.trend < 100 &&
        cliente.projects &&
        cliente.projects.length > 0 &&
        cliente.isActive
      ) {
        noCumplidos++;
      }
    });

    const totalClientes = cumplidos + noCumplidos;
    const porcentajeLogro =
      totalClientes === 0 ? 0 : (cumplidos / totalClientes) * 100;

    return Math.round(porcentajeLogro);
  };

  const handleSearchTermChange = (event) => {
    console.log(event.target.value);
    setSearchTerm(event.target.value);
  };

  const handleStatusSelect = (selectValue) => {
    setStatusData(selectValue);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  /**
   * ESTA FUNCION SE ENCARGA DE ACTUALIZAR LA CANTIDAD DE LEADS
   */
  const handleRequestNOL = async (event) => {
    event.preventDefault();
    const fetchedData = await fetchDataNOL();
    setUpdateDB(fetchedData);
    setTimeout(function () {
      window.location.reload();
    }, 5000);
  };

  if (isLoading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header
          title="DASHBOARD"
          subtitle="Bienvenido a dasboard admin de Alpozo"
        />

        <Box>
          {/* <Button
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={() => refreshAllDB()}
          >
            <CachedOutlined sx={{ mr: "10px" }} />
            He hecho cambios en las Sheets
          </Button> */}
          {/* REVISA LA ULTIMA VEZ QUE SE ACTUALIZO LA DB */}
          {/* {lastDBUpdate && lastDBUpdate.data && lastDBUpdate.data.date && (
            <p>
              Última Actualización:{" "}
              {new Date(lastDBUpdate.data.date || "1900")
                .toISOString()
                .slice(0, 10)}
            </p>
          )} */}
        </Box>
        <Box>
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={(e) => handleRequestNOL(e)}
          >
            <CachedOutlined sx={{ mr: "10px" }} />
            Actualizar Leads
          </Button>
          {/* REVISA LA ULTIMA VEZ QUE SE ACTUALIZO LA DB */}
        </Box>
      </FlexBetween>

      <FlexBetween>
        <CustomSelect
          label={"status"}
          values={statusFilterSelectData}
          onSelect={handleStatusSelect}
        />
        <CustomCheckbox
          isChecked={isChecked}
          label={"A-Z"}
          onChange={handleCheckboxChange}
        />
      </FlexBetween>

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: "span 12" },
        }}
      >
        {/*  sx={{
           "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
         }} */}
        {/* ROW 1 */}
        <StatBox
          title="Total Clientes"
          value={activeDevelopers?.data?.developers || 99}
          increase=""
          description="Since last month"
          icon={
            <Email
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title="Objetivos Cumplidos"
          value={`${handleGetGoals()}%`}
          increase=""
          description="Road to 100%"
          icon={
            <PointOfSale
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />

        {/* <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
        >
          <OverviewChart view="sales" isDashboard={true} />
        </Box>
        <StatBox
          title="Monthly Sales"
          value={data && data.thisMonthStats.totalSales}
          increase="+5%"
          description="Since last month"
          icon={
            <PersonAdd
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title="Yearly Sales"
          value={data && data.yearlySalesTotal}
          increase="+43%"
          description="Since last month"
          icon={
            <Traffic
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        /> */}

        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 3"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              borderRadius: "5rem",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <Searchbar onChange={handleSearchTermChange} value={searchTerm} />

          {!isChecked
            ? filteredData?.map(
                (client) =>
                  !!client.projects.length && (
                    <CustomerDataGrid
                      devName={client.name}
                      projects={client.projects}
                      key={client._id}
                      trend={client.trend}
                    />
                  )
              )
            : sortByName(filteredData).map(
                (client) =>
                  !!client.projects.length && (
                    <CustomerDataGrid
                      devName={client.name}
                      projects={client.projects}
                      key={client._id}
                      trend={client.trend}
                    />
                  )
              )}
        </Box>
        {/* <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            Sales By Category
          </Typography>
          <BreakdownChart isDashboard={true} />
          <Typography
            p="0 0.6rem"
            fontSize="0.8rem"
            sx={{ color: theme.palette.secondary[200] }}
          >
            Breakdown of real states and information via category for revenue
            made for this year and total sales.
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Dashboard;
