import { Backdrop, CircularProgress } from "@mui/material";
import NotificationCard from "components/NotificationCard";
import React from "react";
import { useGetNotificationsQuery } from "state/api";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  gap: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: ${({ header }) => (header ? "#0000" : "#0000")};
  box-shadow: ${({ header }) =>
    header ? "none" : "0px 2px 6px rgba(0, 0, 0, 0.1)"};
  margin-bottom: 10px;
  span {
    font-size: ${({ header }) => (header ? "18px" : "14px")};
    font-weight: ${({ header }) => (header ? "bold" : "normal")};
  }
`;

const Notifications = () => {
  const { data, isLoading } = useGetNotificationsQuery();

  if (isLoading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Wrapper>
      <CardWrapper header>
        <span>Comercializadora</span>
        <span>Proyecto</span>
        <span>Plan</span>
        <span>Visto</span>
        <span>Próxima Notificación</span>
        <span>Resuelto</span>
        <span>Creada el día</span>
      </CardWrapper>
      {data &&
        data.notifs.map((notification) => {
          if(notification.solved) return <></>
          return <NotificationCard key={notification._id} {...notification} />;
        })}
    </Wrapper>
  );
};

export default Notifications;
