import moment from 'moment';
import { sendEmailAlert, updateNextNotification } from './fetch';


export const checkNotifications = async (array) => {
    const currentDate = moment();
    const tomorrow = moment().add(1, 'day');
    const tomorrowFormatted = tomorrow.format('YYYY/MM/DD');
    array.forEach(async notification => {
      const parsedDate = new Date(notification.nextNotification).toISOString().slice(0, 10);
      const notificationDate = moment(parsedDate);
      if (notificationDate.isSame(currentDate, 'day')) {
        // Ingresar a todos los usuarios a los que se les va a mandar la noti
        const text = notification.description;
        await updateNextNotification(notification._id, tomorrowFormatted);
        await sendEmailAlert("nelson@alpozo.com.ar", text);
      }
    });
}