export const calcTotal = (projects) => {
  const summ = {
    totalTar: 0,
    totalLeads: 0,
  };

  projects.forEach((project) => {
    summ["totalTar"] += project["target"];
    summ["totalLeads"] += project["numberOfLeads"];
  });

  return summ;
};

/**
 * 
 * @param {Number} leads 
 * @param {Number} target 
 * @returns Porcentaje para alcanzar el objetivo
 */
export const calcPercentage = (leads, target) => {
  // return (leads * target) / 100;
  return (leads * 100) / target;
};

export const sortDevelopersByPerc = (developers) => {
    // BUSCAR LA FORMA DE ORDENAR EL ARRAY PARA QUE QUEDEN ARRIBA LAS EMPRESAS CON EL PEOR RENDIMIENTO
    

}