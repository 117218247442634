import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  reducerPath: "adminApi",
  tagTypes: [
    "User",
    "Products",
    "Customers",
    "Transactions",
    "Geography",
    "Sales",
    "Admins",
    "Performance",
    "Dashboard",
    "Developer",
    "Notifications",
    "Requests",
  ],
  endpoints: (build) => ({
    getUser: build.query({
      query: (id) => `general/user/${id}`,
      providesTags: ["User"],
    }),
    getProducts: build.query({
      query: () => "client/products",
      providesTags: ["Products"],
    }),
    getCustomers: build.query({
      query: () => "client/customers",
      providesTags: ["Customers"],
    }),
    getTransactions: build.query({
      query: ({ page, pageSize, sort, search }) => ({
        url: "client/transactions",
        method: "GET",
        params: { page, pageSize, sort, search },
      }),
      providesTags: ["Transactions"],
    }),
    getGeography: build.query({
      query: () => "client/geography",
      providesTags: ["Geography"],
    }),
    getSales: build.query({
      query: () => "sales/sales",
      providesTags: ["Sales"],
    }),
    getAdmins: build.query({
      query: () => "management/admins",
      providesTags: ["Admins"],
    }),
    getUserPerformance: build.query({
      query: (id) => `management/performance/${id}`,
      providesTags: ["Performance"],
    }),
    getDashboard: build.query({
      query: () => "general/dashboard",
      providesTags: ["Dashboard"],
    }),
    getNumOfActiveDevs: build.query({
      query: () => "developer/numberOfDevelopers",
      providesTags: ["Developer"]
    }),
    getDeveloperFromMongo: build.query({
      query: () => "developer/getAllDevelopers/mongo",
      providesTags: ["Developer"]
    }),
    getDeveloperById: build.query({
      query: (id) => `/developer/getDeveloper/${id}`,
      providesTags: ["Developer"]
    }),
    createDeveloper: build.mutation({
      query: (developerData) => ({
        url: `/developer/addDeveloper`,
        method: 'POST',
        body: developerData,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
    }),
    updateDeveloper: build.mutation({
      query: ({_id, ...developerData}) => ({
        url: `/developer/updateDeveloper/${_id}`,
        method: 'PUT',
        body: developerData,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
    }),
    createDeveloperMP: build.mutation({
      query: (developerData) => ({
        url: `/developer/addNewDeveloperMP/`,
        method: 'POST',
        body: developerData,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
    }),
    updateDeveloperMP: build.mutation({
      query: (developerData) => ({
        url: `/developer/updateDeveloperImpulsoMP/${developerData.name}`,
        method: 'PUT',
        body: developerData,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
    }),
    getLastDBUpdate: build.query({
      query: () => "requests/getRequestLog",
      providesTags: ["Requests"]
    }),
    getNotifications: build.query({
      query: () => "notifs/getNotifs",
      providesTags: ["Notifications"]
    }),
    createNotifications: build.query({
      query: () => "notifs/createNotifs",
      providesTags: ["Notifications"]
    }),
    updateNotification: build.mutation({
      query: ({_id, ...data}) => ({
        url: `/notifs/updateNotif/${_id}`,
        method: 'PUT',
        body: data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
    })
  }),
});

export const {
  useGetUserQuery,
  useGetProductsQuery,
  useGetCustomersQuery,
  useGetTransactionsQuery,
  useGetGeographyQuery,
  useGetSalesQuery,
  useGetAdminsQuery,
  useGetUserPerformanceQuery,
  useGetDashboardQuery,
  useGetNumOfActiveDevsQuery,
  useGetDeveloperFromMongoQuery,
  useGetNotificationsQuery,
  useUpdateNotificationMutation,
  useCreateDeveloperMutation,
  useCreateDeveloperMPMutation,
  useGetLastDBUpdateQuery, 
  useGetDeveloperByIdQuery,
  useUpdateDeveloperMutation,
  useUpdateDeveloperMPMutation
} = api;
