import React from "react";
import FlexBetween from "./FlexBetween";
import { IconButton, InputBase, useTheme } from "@mui/material";
import { Search } from "@mui/icons-material";

const Searchbar = ({ value, onChange }) => {
  const theme = useTheme();

  return (
    <FlexBetween
      backgroundColor={theme.palette.background.alt}
      borderRadius="9px"
      gap="3rem"
      p="0.1rem 1.5rem"
    >
      <InputBase placeholder="Search..." onChange={onChange} value={value} />
      <IconButton>
        <Search />
      </IconButton>
    </FlexBetween>
  );
};

export default Searchbar;
